<template>
  <div v-loading="isLoading">
    <highcharts
      ref="planChart"
      v-loading="isLoading"
      class="chart"
      :options="chartOptions"
    ></highcharts>
  </div>
</template>
<script>
import Sankey from "highcharts/modules/sankey.js";
import Organisation from "highcharts/modules/organization.js";
import Exporting from "highcharts/modules/exporting.js";
import Accessibility from "highcharts/modules/accessibility.js";
import Highcharts from "highcharts";
import _ from "lodash";
import { mapActions, mapState } from "vuex";
Sankey(Highcharts);
Organisation(Highcharts);
Exporting(Highcharts);
Accessibility(Highcharts);

export default {
  props: {
    report: {
      type: Object,
      required: false
    },

    title: {
      type: String,
      default: ""
    },
    bp_id: {
      type: Number,
      required: true,
      default: 0
    },

    fetchData: {
      type: Boolean,
      default: false
    },
    seriesData: {
      type: Array,
      default: () => [
        {
          data: []
        }
      ],
      required: false
    }
  },

  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          type: "organization",
          inverted: true,
          height: 800,
          paddingRight: 10
        },
        credits: {
          enabled: false
        },
        title: {
          text: __("Plan Tree")
        },
        series: [
          {
            data: []
          }
        ],
        tooltip: {
          enabled: false
        }
      }
    };
  },

  computed: {
    ...mapState("businessplans", {
      isLoading: state => state.isLoading,
      tree: state => state.tree
    })
  },

  methods: {
    ...mapActions("businessplans", {
      getTree: "getTree"
    }),
    loadChartData() {
      this.chartOptions.series[0].data = this.seriesData;
    }
  },

  watch: {
    bp_id: {
      deep: true,
      immediate: true,
      async handler(val) {
        await this.getTree(val);
        let ser = this.tree;
        _.forEach(ser, function(item) {
          return _.reverse(item);
        });
        this.chartOptions.series[0].data = _.reverse(ser);
        if (ser.length * 100 < 400) {
          this.chartOptions.chart.height = 800;
        } else {
          this.chartOptions.chart.height = ser.length * 80;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.chart {
  line-height: 0;
}
.highcharts-container {
  height: 800px !important;
}
</style>
