<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Service Provider") }}</div>
    <div class="details">{{ contentFormInfo.sp_name }}</div>
    <div class="titles">{{ __("Business Plan") }}</div>
    <div class="details">{{ contentFormInfo.bp_name }}</div>
    <div class="titles">{{ __("Pooling Method") }}</div>
    <div class="details">{{ contentFormInfo.pooling_method }}</div>
    <div class="titles">{{ __("Units Allocated") }}</div>
    <div class="details">{{ contentFormInfo.allocated_unit }}</div>
    <div v-if="contentFormInfo.allow_bursting">
      <div class="titles">{{ __("Burst Units") }}</div>
      <div class="details">{{ contentFormInfo.burst_unit }}</div>
    </div>
    <div class="titles">{{ __("Price") }}</div>
    <div class="details">{{ contentFormInfo.unit_price }}</div>
    <div v-if="showTree" style="max-height: 60vh">
      <el-dialog
        :visible.sync="showTree"
        width="65%"
        show-close
        style="margin-top: -12vh!important;"
        :before-close="handleCompareClose"
      >
        <plan-tree :bp_id="contentFormInfo.bp_id"></plan-tree>
      </el-dialog>
    </div>
    <div style="display: flex; padding-top: 20px">
      <el-dropdown @command="handleAction" class="campaignActionDropDown">
        <el-button type="primary">
          {{ __("Actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(command, index) in commandsToShow"
            :key="index"
            :command="command.command"
            :disabled="!canWrite"
          >
            <div
              style="display: flex; justify-content: flex-start; align-items: center"
            >
              <img
                :src="getIconSVG(command.icon)"
                alt
                height="18px"
                width="18px"
                style="margin-right: 10px"
              />
              <span>{{ command.label }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div
      class="details"
      style="padding-top: 20px"
      v-if="
        formAction === 'select' &&
          contentFormInfo.series_data &&
          contentFormInfo.series_data.length
      "
    >
      <plan-hierarchy :series-data="contentFormInfo.series_data">
      </plan-hierarchy>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
    >
      <div class="plan-form-dialog" v-loading="isLoading">
        <el-scrollbar :native="false" style="margin-top: 34px">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="18" :offset="6">
                <el-row type="flex">
                  <el-col :span="22">
                    <page-header
                      style="padding-left: 0"
                      :title="setPageHeader"
                    ></page-header>
                  </el-col>
                </el-row>

                <el-row type="flex">
                  <el-col :span="22">
                    <el-form-item
                      ref="serviceProviderFormItem"
                      :label="__('Service Provider')"
                      prop="sp_id"
                      required
                    >
                      <el-select
                        v-model="contentForm.sp_id"
                        style="width: 100%"
                        @change="fetchBusinessPlans"
                        default-first-option
                        filterable
                      >
                        <el-option
                          v-for="sp in this.serviceProviders"
                          :key="sp.sp_id"
                          :label="sp.sp_name"
                          :value="sp.sp_id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row type="flex">
                  <el-col :span="22">
                    <el-form-item
                      ref="businessPlanFormItem"
                      :label="__('Business Plan')"
                      prop="bp_id"
                    >
                      <el-select
                        v-model="contentForm.bp_id"
                        style="width: 100%"
                        :no-data-text="noBpForSpText"
                        v-loading="this.isReceiving"
                        @change="changeUnitAndPrice"
                        :disabled="contentForm.sp_id === ''"
                        filterable
                        default-first-option
                      >
                        <el-option
                          v-for="bp in this.availablePlans"
                          :key="bp.bp_id"
                          :label="bp.bp_name"
                          :value="bp.bp_id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row type="flex">
                  <el-col :span="22">
                    <el-form-item
                      ref="overridePlanNameFormItem"
                      :label="__('Override Plan Name')"
                      prop="sp_plan_name"
                    >
                      <el-input
                        v-model="contentForm.sp_plan_name"
                        style="width: 100%"
                        v-loading="this.isReceiving && isCheckingName"
                        :disabled="contentForm.sp_id === ''"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <div
                  v-if="
                    contentForm.plan_type === 'IVA' ||
                      contentForm.plan_type === 'Predictive Dialer'
                  "
                >
                  <el-row type="flex">
                    <el-col :span="22">
                      <el-row style="height: 30px;">
                        <el-form-item
                          :label="__('Pooling Method')"
                        ></el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item>
                          <el-radio-group
                            v-model="contentForm.pooling_method"
                            :disabled="contentForm.sp_id === ''"
                            @change="handlePoolingMethodChange"
                          >
                            <el-radio label="Strict">{{
                              __("Strict")
                            }}</el-radio>
                            <el-radio
                              :label="__('Flexible')"
                              :disabled="
                                contentForm.plan_type === 'QforMe Standard' ||
                                  contentForm.plan_type === 'QforMe Premium'
                              "
                              @change="disallowBursting"
                              >{{ __("Flexible") }}
                            </el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>

                <el-row type="flex">
                  <el-col :span="4">
                    <el-form-item
                      ref="allocatedUnitsFormItem"
                      :label="__('Allocated Units')"
                      prop="allocated_unit"
                      :required="contentForm.pooling_method === 'Flexible'"
                    >
                      <!--<el-slider
                    style="margin-left: 5px"
                    v-model="contentForm.allocated_unit"
                    show-input
                    :max="500"
                    :show-tooltip="false"
                  ></el-slider>-->
                      <el-input-number
                        size="small"
                        v-model="contentForm.allocated_unit"
                        :disabled="contentForm.sp_id === ''"
                      >
                      </el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item
                      style="margin-left:50px; margin-top:27px;"
                      prop="allow_bursting"
                    >
                      <el-checkbox
                        v-model="contentForm.allow_bursting"
                        v-if="
                          contentForm.plan_type === 'IVA' &&
                            contentForm.pooling_method !== 'Flexible'
                        "
                        :disabled="contentForm.sp_id === ''"
                        >{{ __("Allow Bursting") }}
                      </el-checkbox>
                      <el-checkbox
                        ref="dvaAllowBurstingCheckbox"
                        v-model="contentForm.allow_bursting"
                        v-else-if="contentForm.plan_type === 'DVA'"
                        :disabled="contentForm.sp_id === ''"
                        >{{ __("Allow Bursting") }}
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>

                <div
                  v-if="
                    contentForm.allow_bursting &&
                      contentForm.plan_type === 'IVA'
                  "
                >
                  <el-row type="flex">
                    <el-col :span="4">
                      <el-form-item
                        :label="__('Burst Units')"
                        prop="burst_unit"
                      >
                        <!--<el-slider
                      style="margin-left: 5px"
                      v-model="contentForm.burst_unit"
                      show-input
                      :min="burst_min"
                      :max="burst_max"
                      :disabled="disable_burst"
                      :show-tooltip="false"
                    ></el-slider>-->
                        <el-input-number
                          size="small"
                          v-model="contentForm.burst_unit"
                          :disabled="disable_burst"
                        >
                        </el-input-number>
                      </el-form-item>
                    </el-col>
                    <el-col>
                      <el-form-item
                        style="margin-left:50px; margin-top:27px;"
                        prop="allow_bursting"
                      >
                        <el-checkbox
                          v-model="contentForm.unlimited_bursting"
                          @change="disableBurstUnit"
                          :disabled="contentForm.sp_id === ''"
                          >{{ __("Unlimited (max 20% of allocated units)") }}
                        </el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div
                  v-if="
                    contentForm.allow_bursting &&
                      contentForm.plan_type === 'DVA'
                  "
                >
                  <el-row type="flex">
                    <el-col :span="8">
                      <el-form-item
                        :label="__('Maximum Burst Percentage')"
                        prop="burst_unit"
                      >
                        <el-input-number
                          ref="dvaMaximumBurstPercentageInputNumber"
                          size="small"
                          :min="0"
                          v-model="contentForm.burst_unit"
                          :disabled="disable_burst"
                        >
                        </el-input-number>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <el-row type="flex">
                  <el-col :span="4">
                    <el-form-item :label="__('List Price')" prop="list_price">
                      <el-input-number
                        size="small"
                        :min="0"
                        v-model="contentForm.list_price"
                        :disabled="true"
                      >
                      </el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item
                      style="margin-left:50px; margin-top:27px;"
                      prop="override_price"
                    >
                      <el-checkbox
                        v-model="contentForm.override_price"
                        :disabled="contentForm.sp_id === ''"
                        @change="updateUnitPrice"
                        >{{ __("Override Price") }}
                      </el-checkbox>
                      <span
                        v-if="contentForm.override_price"
                        style="font-size: small; margin-left:50px; margin-top:27px;"
                      >
                        <!-- eslint-disable-next-line -->
                        {{ __("Please ensure you have written authority to override list pricing before continuing.") }}
                      </span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex">
                  <el-col :span="22">
                    <el-form-item
                      :label="__('Unit Price')"
                      prop="unit_price"
                      v-if="contentForm.override_price"
                    >
                      <el-input-number
                        size="small"
                        :min="0"
                        v-model="contentForm.unit_price"
                      >
                      </el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8" :offset="1">
                <div style="padding-top: calc(100vh - 81vh)">
                  <plan-hierarchy
                    :series-data="contentForm.series_data"
                    v-if="contentForm.plan_type === 'IVA'"
                  >
                  </plan-hierarchy>
                </div>
              </el-col>
            </el-row>
          </el-form>
          <el-row type="flex">
            <el-col :span="22" :offset="6">
              <save-button
                type="primary"
                @click="createOrEditSpPlan"
                class="submitBtn"
                v-loading="isSubmitting"
                :disabled="!canWrite() || contentForm.sp_id === ''"
                :primaryKey="id"
                variant="AssignUpdate"
              />
              <el-button @click="handleCancelBtn" class="cancelBtn"
                >{{ __("Cancel") }}
              </el-button>
            </el-col>
          </el-row>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PlanTree from "@/views/billing/business-plans/pages/PlanTree";
import PlanHierarchy from "@/views/billing/business-plans/pages/PlanHierarchy";
import { checkUniqueName } from "@/api/spplans";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton,
    PlanTree,
    PlanHierarchy
  },
  data() {
    const checkSelectedSPList = (rule, value, callback) => {
      if (!this.selectedServiceProvider) {
        callback(__("Service Provider is required"));
      } else {
        callback();
      }
    };
    const checkSelectedBPList = (rule, value, callback) => {
      if (!this.selectedBusinessPlans) {
        callback(__("Business Plan is required"));
      } else {
        callback();
      }
    };
    const validateSpPlanName = async (rule, value, callback) => {
      try {
        if (value !== this.contentFormInfo.sp_plan_name || this.id === -1) {
          this.isCheckingName = true;
          const res = await checkUniqueName(value, this.contentForm.sp_id);
          this.isCheckingName = false;
          if (res.data.found) {
            callback(
              new Error(__("Service Provider Plan name already exists"))
            );
          } else {
            callback();
          }
        } else {
          callback();
        }
      } catch (e) {
        this.isCheckingName = false;
        callback();
      }
    };
    const checkMinUnits = (rule, value, callback) => {
      if (!this.unit) {
        callback(
          // eslint-disable-next-line
          __("Allocated Units are required. This would be numbers of session or waiter or dialer etc.")
        );
      } else {
        callback();
      }
    };
    const checkUnitPrice = (rule, value, callback) => {
      if (!this.price) {
        callback(
          // eslint-disable-next-line
          __("Units Price is required. This would be price per session or waiter or dialer etc.")
        );
      } else {
        callback();
      }
    };
    return {
      spPlan: {},
      isSubmitting: false,
      disable_burst: false,
      spPlanFormKey: 0,
      isReceiving: false,
      isCheckingName: false,
      selectedServiceProvider: [],
      selectedBusinessPlans: [],
      price: "",
      unit: "",
      burst_unit: 0,
      burst_min: 0,
      burst_max: 100,
      showTree: false,
      noBpForSpText: __("Please select a service provider from the list."),
      commands: [
        {
          command: "Tree view",
          rules: {
            plan_type: "iva"
          },
          icon: "icon-tree.svg",
          selectAction: false,
          label: __("Tree view")
        },
        {
          command: "createOrEdit",
          rules: {},
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit"),
          selectAction: false
        },
        {
          command: "delete",
          rules: {},
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Delete"),
          selectAction: false
        }
      ],
      rules: {
        service_provider: [
          {
            required: true,
            trigger: "blur",
            validator: checkSelectedSPList
          }
        ],
        business_plan: [
          {
            required: true,
            trigger: "blur",
            validator: checkSelectedBPList
          }
        ],
        bp_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Business Plan ID is required")
          }
        ],
        sp_plan_name: [
          {
            required: true,
            trigger: "blur",
            message: __("SP Plan Name is required")
          },
          {
            required: true,
            trigger: "blur",
            validator: validateSpPlanName
          }
        ],
        unit: [
          {
            required: true,
            trigger: "blur",
            validator: checkMinUnits
          }
        ],
        price: [
          {
            required: true,
            trigger: "blur",
            validator: checkUnitPrice
          }
        ]
      }
    };
  },
  computed: {
    setPageHeader: function() {
      return this.id === -1
        ? __("Assign :plan_type Plan", {
            plan_type: this.contentForm.plan_type
          })
        : __("Edit :plan_type Plan", {
            plan_type: this.contentForm.plan_type
          });
    },
    ...mapState("spplans", {
      isLoading: state => state.isLoading,
      availablePlans: state => state.availablePlans,
      serviceProviders: state => state.serviceProviders
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return rule.includes(this.contentForm[key].toLowerCase());
        });
      });
    }
  },

  beforeRouteLeave(to, from, next) {
    this.spPlan = {};
    this.selectedBusinessPlans = "";
    this.selectedServiceProvider = "";
    this.price = "";
    this.unit = "";
    this.noBpForSpText = __("Please select a service provider from the list.");
    next();
  },

  methods: {
    ...mapActions("spplans", {
      createSpPlan: "createSpPlan",
      updateSpPlan: "updateSpPlan",
      deleteContentMethod: "deleteSpPlan",
      undoDeleteContent: "undoDeleteSpPlan",
      getBusinessPlansOfSameType: "getBusinessPlansOfSameType",
      getAvailablePlansForSP: "getAvailablePlansForSP",
      getServiceProvidersForBusinessPlans:
        "getServiceProvidersForBusinessPlans",
      getSpForEditMode: "getSpForEditMode"
    }),

    ...mapActions("businessplans", {
      getBusinessPlans: "getBusinessPlans"
    }),
    ...mapActions("serviceproviders", {
      getServiceProviders: "getServiceProviders"
    }),
    ...mapActions("app", {
      getEnabledFeaturesForUser: "getEnabledFeaturesForUser"
    }),

    handlePoolingMethodChange() {
      if (this.contentForm.pooling_method === "Flexible") {
        this.contentForm.burst_unit = 0;
      }
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    disableBurstUnit() {
      this.disable_burst = !this.disable_burst;
    },

    disallowBursting() {
      this.contentForm.allow_bursting = false;
    },

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    handleAction(action) {
      if (action === "delete") {
        this.handleDelete(this.contentForm);
      } else if (action === "createOrEdit") {
        this.handleEdit();
      } else {
        this.handleTreeView();
      }
    },

    handleTreeView() {
      this.showTree = true;
    },

    handleCompareClose() {
      this.showTree = false;
    },

    /**
     * getAvailablePlansForSP -
     * in create page, once sp is selected in dropdown, fetch possible business plans to show in dropdown.
     * ie if sp has a plan of session unit then show the plans in dropdown except session only.
     */
    async fetchBusinessPlans(sp_id) {
      this.isReceiving = true;
      this.contentForm.bp_id = "";
      await this.getAvailablePlansForSP({
        sp_id: sp_id,
        plan_type: this.contentForm.plan_type
      });
      if (!this.availablePlans.length) {
        // eslint-disable-next-line
        this.noBpForSpText = __("Currently, there is no business plan available which can be assigned to this service provider.");
      }
      this.isReceiving = false;
    },

    async fetchSpForEditMode(options) {
      this.isReceiving = true;
      await this.getSpForEditMode(options);
      this.isReceiving = false;
    },

    async fetchBusinessPlansForEditMode(options) {
      this.isReceiving = true;
      await this.getBusinessPlansOfSameType(options);
      this.isReceiving = false;
    },

    async changeUnitAndPrice(bp_id) {
      let bp_item = _.find(this.availablePlans, {
        bp_id: bp_id
      });
      this.contentForm.allocated_unit = bp_item.min_unit;
      this.contentForm.unit_price = bp_item.price_per_unit;
      this.contentForm.list_price = bp_item.price_per_unit;
      this.contentForm.series_data = bp_item.series_data;
    },

    createOrEditSpPlan() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createSpPlan : this.updateSpPlan;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Business Plan assigned successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Assigned Plan updated successfully")
                  });
              this.isSubmitting = false;
              EventBus.$emit("list-changed", data.data);
              if (this.selectedAccountId !== "all") {
                this.getEnabledFeaturesForUser(this.selectedAccountId);
              }
              this.handleCancel();
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    updateUnitPrice() {
      this.contentForm.unit_price = this.contentForm.override_price
        ? this.contentForm.unit_price
        : this.contentForm.list_price;
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val === "edit") {
          this.fetchSpForEditMode({
            sp_id: this.contentForm.sp_id
          });
          this.fetchBusinessPlansForEditMode({
            sp_id: this.contentFormInfo.sp_id,
            bp_id: this.contentFormInfo.bp_id
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

::v-deep .plan-form-dialog {
  max-height: 90vh !important;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }

  .el-select__tags {
    ::v-deep .el-tag {
      background-color: $content-theme-color !important;
      border-color: $content-theme-color !important;

      .el-tag__close {
        background-color: $content-theme-color !important;
      }
    }
  }
}

::v-deep .el-radio {
  :hover {
    border-color: $content-theme-color;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $content-theme-color;
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}

::v-deep .el-slider {
  border-color: $content-theme-color;
  :hover {
    border-color: $content-theme-color;
  }

  .el-slider__bar {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-slider__button {
    border-color: $content-theme-color;
    color: $content-theme-color;
  }

  ::v-deep .el-input-number--small .el-input-number__increase {
    :hover {
      color: $content-theme-color;
    }
  }
  ::v-deep .el-input-number--small .el-input-number__decrease {
    :hover {
      color: $content-theme-color;
    }
  }
}
.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}
</style>
