<script>
import { mapActions, mapState } from "vuex";
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "SpPlansIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("spplans", {
      contents: state => state.spPlans
    })
  },

  methods: {
    ...mapActions("businessplans", {
      getBusinessPlans: "getBusinessPlans"
    }),
    ...mapActions("spplans", {
      getServiceProvidersForBusinessPlans:
        "getServiceProvidersForBusinessPlans",
      setBusinessPlanFilter: "setBusinessPlanFilter"
    }),
    initContent(planType = "IVA") {
      return {
        sp_plan_name: "",
        sp_id: "",
        bp_id: "",
        unit_name: "",
        plan_type: planType,
        allocated_unit: 0,
        burst_unit: 0,
        unit_price: 1,
        pooling_enabled: false,
        pooling_method: "Strict",
        allow_bursting: false,
        unlimited_bursting: false,
        override_price: false,
        list_price: 0
      };
    },
    handleAdd(planType) {
      this.formAction = "add";
      this.setSelectedObject(-1, this.initContent(planType));
      this.getServiceProvidersForBusinessPlans({ plan_type: planType });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setBusinessPlanFilter("IVA");
    this.initContent();
    next();
  },
  mounted() {
    this.contentType = "Service Provider Plan";
    this.primaryKey = "sp_plan_id";
    this.scope = "sys";
    //this.getNodeTypes({ plan_type: "IVA" });
    //this.getServiceProvidersForBusinessPlans({ plan_type: "IVA" });
    //this.getBusinessPlans();
  }
};
</script>

<style lang="scss" scoped></style>
